import React from 'react'
import styled from "styled-components"
import Seo from "../components/_shared/Seo"
import {Link} from "gatsby"

const ContactWrapper = styled.div`
  max-width: var(--fixed-width);
  margin: 150px auto 220px;
  padding: 0 20px;
`

const ContactSuccessPage = props => {

    return (
        <>
            <Seo title="Viesti lähetetty"/>
            <ContactWrapper>
                <h2>Kiitoksia yhteydenotostasi!</h2>
                <p>Viestisi on vastaanotettu...</p>

                <Link to="/">
                    <button type="button" className="btn">Palaa pääsivulle</button>
                </Link>
            </ContactWrapper>
        </>
    )
}

export default ContactSuccessPage;
